.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to ensure it's above other content */
}

.card {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px; /* Adjust as necessary */
  max-height: 80vh;
  overflow-y: auto;
  /* Include other styles to match the look of Login and SignUp components */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
