.subtitle {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    margin-top: 20px; /* Adjust as needed. */
    color: #222;
  }
  
.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    box-sizing: border-box;
}

.faq-title {
    margin-bottom: 20px;
}

.faq-section {
    width: 100%;
    border-radius: 10px;
    padding: 20px;
}

.accordion-button {
    font-weight: 600;
    /* font-size: 20px; */
}

.accordion-body {
    /* font-size: 18px;
    font-weight: 600; */
    text-align: left;
}

.accordion-header {
    /* font-size: 18px;
    font-weight: 600; */
    margin-top: 0px;
}
  



@media (max-width: 767px) {

    .faq-container {
        width: 90%;
    }
    .faq-section {
        padding: 0px;
    }
    
  
  }
