/* src/global.css */
  
:root {
    /* Colors */
    --moonless-night-black: #000017;
    --oceans-deep-blue: #00003D;
    --night-sky-blue: #00008A;
    --silvery-moon-grey: #EAEAEA;
    --starlight-white: #FFFFFF;

    --box-shadow-light: 0px 4px 8px rgba(0, 0, 0, 0.2);
    --box-shadow-deep: 0px 6px 12px rgba(0, 0, 0, 0.3);
  
    /* Typography */
    --font-family-montserrat: "Montserrat", sans-serif;
    --font-family-georgia: 'Georgia', serif;
    --font-size-display: 60px; /* Display font size for headings */  }

  .page-container {
    width: 75%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  
  body {
    font-family: var(--font-family-montserrat);
    color: var(--moonless-night-black);
    line-height: 1.6;
  }

  .display-font {
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-display);
    font-weight: bold;
    line-height: 1.2; /* Adjust line-height for display text */
  }
  
  
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-montserrat);
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  h1 {
    font-size: 36px;
    font-weight: bold;
  }
  
  h2 {
    font-size: 30px;
    font-weight: 600; /* SemiBold */
  }
  
  h3 {
    font-size: 24px;
    font-weight: 600; /* SemiBold */
  }
  
  h4 {
    font-size: 20px;
    font-weight: normal;
  }
  
  p {
    font-size: 16px;
  }
  
  small, .text-small {
    font-size: 14px;
  }
  
  blockquote {
    font-size: 18px;
    font-style: italic;
  }
  
  button, .btn {
    font-family: var(--font-family-montserrat);
    font-size: 16px;
    font-weight: 600; /* SemiBold */
  }

  .text-center {
    text-align: center;
  }
  
  .m-0 {
    margin: 0;
  }
  
  .p-10 {
    padding: 10px;
  }

input,
button,
textarea,
select {
  font-family: var(--font-family-montserrat);
  margin: 0; /* 2 */
}

a {
    color: var(--night-sky-blue);
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  

  :root {
  --color-dark: #000017; /* Moonless Night Black */
  --box-shadow-light: 0px 4px 8px rgba(0, 0, 0, 0.2);
  --box-shadow-deep: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

  

  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  }
  
