.signup-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}
.signup-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
}

.bold-label {
    font-weight: bold;
}

.custom-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.center-text {
    text-align: center;
}
