body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* src/global.css */
  
:root {
    /* Colors */
    --moonless-night-black: #000017;
    --oceans-deep-blue: #00003D;
    --night-sky-blue: #00008A;
    --silvery-moon-grey: #EAEAEA;
    --starlight-white: #FFFFFF;

    --box-shadow-light: 0px 4px 8px rgba(0, 0, 0, 0.2);
    --box-shadow-deep: 0px 6px 12px rgba(0, 0, 0, 0.3);
  
    /* Typography */
    --font-family-montserrat: "Montserrat", sans-serif;
    --font-family-georgia: 'Georgia', serif;
    --font-size-display: 60px; /* Display font size for headings */  }

  .page-container {
    width: 75%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  
  body {
    font-family: var(--font-family-montserrat);
    color: var(--moonless-night-black);
    line-height: 1.6;
  }

  .display-font {
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-display);
    font-weight: bold;
    line-height: 1.2; /* Adjust line-height for display text */
  }
  
  
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-montserrat);
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  h1 {
    font-size: 36px;
    font-weight: bold;
  }
  
  h2 {
    font-size: 30px;
    font-weight: 600; /* SemiBold */
  }
  
  h3 {
    font-size: 24px;
    font-weight: 600; /* SemiBold */
  }
  
  h4 {
    font-size: 20px;
    font-weight: normal;
  }
  
  p {
    font-size: 16px;
  }
  
  small, .text-small {
    font-size: 14px;
  }
  
  blockquote {
    font-size: 18px;
    font-style: italic;
  }
  
  button, .btn {
    font-family: var(--font-family-montserrat);
    font-size: 16px;
    font-weight: 600; /* SemiBold */
  }

  .text-center {
    text-align: center;
  }
  
  .m-0 {
    margin: 0;
  }
  
  .p-10 {
    padding: 10px;
  }

input,
button,
textarea,
select {
  font-family: var(--font-family-montserrat);
  margin: 0; /* 2 */
}

a {
    color: var(--night-sky-blue);
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  

  :root {
  --color-dark: #000017; /* Moonless Night Black */
  --box-shadow-light: 0px 4px 8px rgba(0, 0, 0, 0.2);
  --box-shadow-deep: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

  

  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  }
  

/* Button.module.css */
.Button_default__Qx-aG {
  padding: 10px 20px;
  font-weight: 500; /* Adjusted to a lighter font-weight */
  font-size: 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease, color 0.3s ease; /* Added color to the transition */
  font-family: var(--font-family-montserrat);
  display: inline-flex; /* Ensure flex layout to align icon and text */
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center content horizontally */
}

  
  .Button_primary__m7MWU {
    background-color: var(--color-dark);
    color: white;
    box-shadow: var(--box-shadow-light);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .Button_primary__m7MWU:hover {
    background-color: var(--night-sky-blue); /* Change to blue on hover */
    box-shadow: var(--box-shadow-deep);
  }
  
  .Button_secondary__2AnAE {
    background-color: #f0f0f0;
    color: var(--color-dark);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .Button_secondary__2AnAE:hover {
    box-shadow: var(--box-shadow-deep);
  }
  
  .Button_tertiary__26oh0 {
    background-color: transparent; /* No background */
    color: var(--color-dark); /* Initial color */
    box-shadow: none; /* No box shadow */
    transition: color 0.3s ease, box-shadow 0.3s ease; /* Smooth color transition */
    text-decoration: none; /* No underline by default */
  }
  
  .Button_tertiary__26oh0:hover {
    color: var(--night-sky-blue); /* Change text color on hover */
    background-color: transparent; /* Remain transparent */
    box-shadow: none; /* No box shadow on hover */
    text-decoration: underline; /* Underline on hover */
  }
    

.Button_icon__3lbir {
  margin-right: 8px; /* Space between icon and text */
  vertical-align: middle; /* Align icon vertically with text */
}

.Button_active__th1n4 {
  color: var(--night-sky-blue); /* Or your theme's active color */
  text-decoration: underline; /* Underline style */
}

  
/* Header2.module.css */
.Navbar_header__3amlm {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 10px 20px;
  }
  
  .Navbar_logo__1tVVQ {
    height: 75px;
  }
  
  .Navbar_nav__1Vswi {
    display: flex;
    align-items: center;
    grid-gap: 50px;
    gap: 50px;
  }

  .Navbar_profileIcon__2MHKs {
    color: black; /* Sets the color of the profile icon to black */
    cursor: pointer; /* Changes the cursor to pointer on hover */
    font-size: 26px; /* Sets the initial font size for the icon */
    transition: color 0.3s; /* Smooth color transition for hover effect */
  }
  
  .Navbar_profileIcon__2MHKs:hover {
    color: #00008A; /* Night Sky Blue color on hover */
  }
    
  
  @media (max-width: 767px) {
    .Navbar_nav__1Vswi {
      display: none; /* Hide navigation links on small screens */
    }
    .Navbar_header__3amlm {
      justify-content: center; /* Center the header contents */
    }
  }
  
.subtitle {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    margin-top: 20px; /* Adjust as needed. */
    color: #222;
  }
  
.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    box-sizing: border-box;
}

.faq-title {
    margin-bottom: 20px;
}

.faq-section {
    width: 100%;
    border-radius: 10px;
    padding: 20px;
}

.accordion-button {
    font-weight: 600;
    /* font-size: 20px; */
}

.accordion-body {
    /* font-size: 18px;
    font-weight: 600; */
    text-align: left;
}

.accordion-header {
    /* font-size: 18px;
    font-weight: 600; */
    margin-top: 0px;
}
  



@media (max-width: 767px) {

    .faq-container {
        width: 90%;
    }
    .faq-section {
        padding: 0px;
    }
    
  
  }

.card-section {
    margin-left: 80px;  /* Increase the margin around the card section */
    margin-right: 80px;
    margin-bottom: 80px;
}

.blog-subtitle {
    text-align: center;  /* Increase the margin around the card section */
    
}

.card-section h1 {
    text-align: left;
}

.card-list {
    display: flex;
    overflow-x: auto;
    grid-gap: 40px;
    gap: 40px;
    padding: 10px;  /* Add padding around the cards */
}


@media (max-width: 767px) {
    .card-section {
        margin:0px;  /* Increase the margin around the card section */
    }

}
.signup-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}
.signup-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
}

.bold-label {
    font-weight: bold;
}

.custom-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.center-text {
    text-align: center;
}

.signup-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.InputOverlay_overlay__3GV2s {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to ensure it's above other content */
}

.InputOverlay_card__mDW7R {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px; /* Adjust as necessary */
  max-height: 80vh;
  overflow-y: auto;
  /* Include other styles to match the look of Login and SignUp components */
}

.InputOverlay_closeButton__1rwr3 {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.blog-container {
    width: 60%;
    margin: auto;
    margin-bottom: 100px;
    text-align: left;
    
    background-color: #f9f9f9; /* light gray background */
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Optional: for a subtle shadow around the container */
}

.blog-header {
    font-size: 24px;
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

.blog-subheader {
    font-size: 20px;
    font-weight: bold;
    color: #777;
    margin-bottom: 20px;
}

.blog-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-paragraph {
    font-family: 'Georgia', serif;
    color: #333; /* dark gray color for text */
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.blog-date {
    font-size: 16px;
    color: #999; /* light gray color for date */
    margin-bottom: 20px;
}


@media (max-width: 767px) {
    .blog-container {
        width: 90%;
    }

}
/* Callout.module.css */

.Callout_callout__2C76h {
    padding: 10px 20px;
    background-color: #EAEAEA; /* Silvery Moon Grey */
    border-radius: 10px; /* Adjust as needed for rounded corners */
    font-size: 16px; /* Body text size */
    margin: 10px 0; /* Optional: Adjust as needed for spacing */
  }
  
