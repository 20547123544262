/* Header2.module.css */
.header {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 10px 20px;
  }
  
  .logo {
    height: 75px;
  }
  
  .nav {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .profileIcon {
    color: black; /* Sets the color of the profile icon to black */
    cursor: pointer; /* Changes the cursor to pointer on hover */
    font-size: 26px; /* Sets the initial font size for the icon */
    transition: color 0.3s; /* Smooth color transition for hover effect */
  }
  
  .profileIcon:hover {
    color: #00008A; /* Night Sky Blue color on hover */
  }
    
  
  @media (max-width: 767px) {
    .nav {
      display: none; /* Hide navigation links on small screens */
    }
    .header {
      justify-content: center; /* Center the header contents */
    }
  }
  