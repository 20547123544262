/* Button.module.css */
.default {
  padding: 10px 20px;
  font-weight: 500; /* Adjusted to a lighter font-weight */
  font-size: 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease, color 0.3s ease; /* Added color to the transition */
  font-family: var(--font-family-montserrat);
  display: inline-flex; /* Ensure flex layout to align icon and text */
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center content horizontally */
}

  
  .primary {
    background-color: var(--color-dark);
    color: white;
    box-shadow: var(--box-shadow-light);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .primary:hover {
    background-color: var(--night-sky-blue); /* Change to blue on hover */
    box-shadow: var(--box-shadow-deep);
  }
  
  .secondary {
    background-color: #f0f0f0;
    color: var(--color-dark);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .secondary:hover {
    box-shadow: var(--box-shadow-deep);
  }
  
  .tertiary {
    background-color: transparent; /* No background */
    color: var(--color-dark); /* Initial color */
    box-shadow: none; /* No box shadow */
    transition: color 0.3s ease, box-shadow 0.3s ease; /* Smooth color transition */
    text-decoration: none; /* No underline by default */
  }
  
  .tertiary:hover {
    color: var(--night-sky-blue); /* Change text color on hover */
    background-color: transparent; /* Remain transparent */
    box-shadow: none; /* No box shadow on hover */
    text-decoration: underline; /* Underline on hover */
  }
    

.icon {
  margin-right: 8px; /* Space between icon and text */
  vertical-align: middle; /* Align icon vertically with text */
}

.active {
  color: var(--night-sky-blue); /* Or your theme's active color */
  text-decoration: underline; /* Underline style */
}

  