.blog-container {
    width: 60%;
    margin: auto;
    margin-bottom: 100px;
    text-align: left;
    
    background-color: #f9f9f9; /* light gray background */
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Optional: for a subtle shadow around the container */
}

.blog-header {
    font-size: 24px;
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

.blog-subheader {
    font-size: 20px;
    font-weight: bold;
    color: #777;
    margin-bottom: 20px;
}

.blog-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-paragraph {
    font-family: 'Georgia', serif;
    color: #333; /* dark gray color for text */
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.blog-date {
    font-size: 16px;
    color: #999; /* light gray color for date */
    margin-bottom: 20px;
}


@media (max-width: 767px) {
    .blog-container {
        width: 90%;
    }

}