.card-section {
    margin-left: 80px;  /* Increase the margin around the card section */
    margin-right: 80px;
    margin-bottom: 80px;
}

.blog-subtitle {
    text-align: center;  /* Increase the margin around the card section */
    
}

.card-section h1 {
    text-align: left;
}

.card-list {
    display: flex;
    overflow-x: auto;
    gap: 40px;
    padding: 10px;  /* Add padding around the cards */
}


@media (max-width: 767px) {
    .card-section {
        margin:0px;  /* Increase the margin around the card section */
    }

}